import { Button, IconButton, InputAdornment, TextField, withStyles } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import classNames from 'classnames';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withAuthContext } from '../contexts';

const styles = theme => ({
  textField: {
    color: 'red',
  },
  flexChild: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    margin: 'auto',
  },
  widthOfLogin: {
    width: '320px',
    maxWidth: '96%',
  },
  loginButton: {
    marginTop: 20,
  },
});

class SignUp extends React.PureComponent {
  state = {
    name: '',
    password: '',
    secondPassword: '',
    secondPasswordMismatch: false,
    secondPasswordErrorMessage: 'Your passwords do not match, please ensure that they do.',
    showPassword: false,
  };
  handleChange = property => changedValue => {
    this.setState({ [property]: changedValue.target.value });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  secondPasswordMismatch = () => this.state.password !== this.state.secondPassword;
  handleFormSubmit = e => {
    e.preventDefault();
    if (!this.secondPasswordMismatch()) {
      this.props.authContext.mutationFunctions.doCreateUserWithEmailAndPassword(this.state.name, this.state.password);
      this.props.history.push('/dashboard');
    }
  };
  secondPasswordErrorMessage = () => (this.inputLabelError() ? this.state.secondPasswordErrorMessage : null);
  inputLabelError = () => this.state.secondPassword !== '' && this.secondPasswordMismatch();
  render() {
    const { classes } = this.props;
    const adornment = (
      <InputAdornment id='inputAdrn' position='end'>
        <IconButton aria-label='Toggle password visibility' onClick={this.handleClickShowPassword}>
          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
    const endAdornment = {
      endAdornment: adornment,
    };
    const heading = <h2> Sign Up for Shutdown! </h2>;
    const userName = (
      <TextField
        id='outlined-login'
        autoComplete='username'
        label='Email or Usernames'
        className={classes.textField}
        value={this.state.name}
        onChange={this.handleChange('name')}
        margin='normal'
        variant='outlined'
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
    );
    const password = (
      <TextField
        id='outlined-password'
        autoComplete='new-password'
        type={this.state.showPassword ? 'text' : 'password'}
        label='Password'
        className={`${classes.textField} outLinedPassword`}
        value={this.state.password}
        onChange={this.handleChange('password')}
        margin='normal'
        variant='outlined'
        InputProps={endAdornment}
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
    );
    const secondPassword = (
      <TextField
        id='outlined-confirm-password'
        label='Confirm Password'
        autoComplete='new-password'
        className={classes.textField}
        value={this.state.secondPassword}
        onChange={this.handleChange('secondPassword')}
        margin='normal'
        variant='outlined'
        type='password'
        helperText={this.secondPasswordErrorMessage()}
        error={this.inputLabelError()}
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
    );
    const submitButton = (
      <Button type='submit' variant='contained' color='secondary' className={classes.loginButton} fullWidth>
        Sign Up!
      </Button>
    );
    return (
      <div>
        <form className={classNames(classes.widthOfLogin, classes.flexChild)} onSubmit={this.handleFormSubmit}>
          <div>{heading}</div>
          <div>{userName}</div>
          <div>{password}</div>
          <div>{secondPassword}</div>
          <div>{submitButton}</div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(withAuthContext(SignUp)));
