import React from 'react';
import httpApiInterface from '../actions/axios';
import firebase from '../firebase';
import { apiFetchContext, AuthContext } from './reactContexts';

class DataProvider extends React.Component {
  state = {
    authUserContext: {
      data: {
        firebaseAuthObject: '',
      },
      mutationFunctions: {
        updateAuthdataFromApi: fbDoc => {
          const previousUID =
            this.state.authUserContext.data.firebaseAuthObject && this.state.authUserContext.data.firebaseAuthObject.uid
              ? this.state.authUserContext.data.firebaseAuthObject.uid
              : null;
          const currentUID = fbDoc ? fbDoc.uid : null;
          this.setState((state, _ /* props */) => {
            state.authUserContext = {
              ...state.authUserContext,
              data: {
                ...state.authUserContext.data,
                firebaseAuthObject: fbDoc,
              },
            };
            return state;
          });

          if (previousUID !== currentUID) {
            // We have something to do.
            if (currentUID == null) {
              // this is a typical logout. Unsubscribe.
              this.apiDataStateListenerSubscription();
              this.apiDataAwsStateListenerSubscription();
            } else if (previousUID == null) {
              this.apiDataStateListenerSubscription = firebase.addUserStoreListener(
                this.state.apiFetchContext.mutationFunctions.updateFirestoreUserObject,
              );
              this.apiDataAwsStateListenerSubscription = firebase.addAWSStoreListener(
                this.state.apiFetchContext.mutationFunctions.updateFirestoreUserAWSObject,
              );
            } else {
              // The user id is changing.
              // unsubscribe first
              this.apiDataStateListenerSubscription();
              this.apiDataAwsStateListenerSubscription();
              // resubscribe again
              this.apiDataStateListenerSubscription = firebase.addUserStoreListener(
                this.state.apiFetchContext.mutationFunctions.updateFirestoreUserObject,
              );
              this.apiDataAwsStateListenerSubscription = firebase.addAWSStoreListener(
                this.state.apiFetchContext.mutationFunctions.updateFirestoreUserAWSObject,
              );
            }
          }
        },
        doLoginWithGoogle: firebase.doSignInWithGoogle,
        doLoginWithGithub: firebase.doSignInWithGithub,
        doLogin: (login, password) => firebase.doSignInWithEmailAndPassword(login, password),
        doCreateUserWithEmailAndPassword: (email, password) =>
          firebase.doCreateUserWithEmailAndPassword(email, password),
        doLogout: () => firebase.doSignOut(),
      },
    },
    apiFetchContext: {
      data: {
        firestoreUserObject: {
          rememberMe: true,
          tosAccepted: false,
        },
        firestoreUserAWSObject: {
          AWS_ACCESS_KEY_ID: '',
          AWS_ACCESS_KEY_SECRET: '',
          AWS_EC2_REGION: '',
          awsAccessKeyNotes: '',
        },
        aws_ec2_instances: '',
      },
      mutationFunctions: {
        updateFirestoreUserObject: fbDoc => {
          fbDoc.exists &&
            this.setState((state, _ /* props */) => {
              state.apiFetchContext = {
                ...state.apiFetchContext,
                data: {
                  ...state.apiFetchContext.data,
                  firestoreUserObject: fbDoc.data(),
                },
              };
              return state;
            });
        },
        updateFirestoreUserAWSObject: fbDoc => {
          fbDoc.exists &&
            this.setState((state, _ /* props */) => {
              state.apiFetchContext = {
                ...state.apiFetchContext,
                data: {
                  ...state.apiFetchContext.data,
                  firestoreUserAWSObject: {
                    ...state.apiFetchContext.data.firestoreUserAWSObject,
                    ...fbDoc.data(),
                  },
                },
              };
              return state;
            });
        },
        updateAWSEC2Instances: (key, value) => {
          this.setState((state, _) => {
            state.apiFetchContext.data[key] = value;
            state.apiFetchContext = { ...state.apiFetchContext };
            return state;
          });
        },
        updateAWSInstanceState: (InstanceId, operation) => {
          httpApiInterface
            .updateAWSInstanceState(InstanceId, operation)
            .then(successful => {
              // Update again in 20 seconds.
              setTimeout(this.state.apiFetchContext.mutationFunctions.getEC2Data, 20000);
              // Update right away.
              this.state.apiFetchContext.mutationFunctions.getEC2Data();
            })
            .catch(error => {
              console.error('Error with the backend', error);
            });
        },
        updateUserData: newData => {
          firebase.updateUserData(newData);
        },
        updateUserAWSData: newData => {
          firebase.updateUserAwsData(newData);
        },
        getEC2Data: () => {
          const handleSuccess = (key, mutationFunction) => response => {
            mutationFunction(key, {
              response: response.data,
              success: true,
              error: false,
            });
          };
          const handleInstanceSuccess = handleSuccess(
            'aws_ec2_instances',
            this.state.apiFetchContext.mutationFunctions.updateAWSEC2Instances,
          );
          const handleKeyPairsSuccess = handleSuccess(
            'aws_ec2_keypairs',
            this.state.apiFetchContext.mutationFunctions.updateAWSEC2Instances,
          );
          const handleError = exception => {
            var response = '';
            const success = false;
            const error = true;
            var errorMessage = '';
            if (exception.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              errorMessage = `The server had a ${exception.response.status} error`;
              response = exception.response.data;
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            } else if (exception.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              // console.log(error.request);
              errorMessage = exception.request;
            } else {
              // Something happened in setting up the request that triggered an Error
              errorMessage = exception.message;
            }
            this.state.apiFetchContext.mutationFunctions.updateAWSEC2Instances({
              response,
              success,
              error,
              errorMessage,
            });
          };
          httpApiInterface
            .getEC2Instances()
            .then(handleInstanceSuccess)
            .catch(handleError);
          httpApiInterface
            .getEC2KeyPairs()
            .then(handleKeyPairsSuccess)
            .catch(handleError);
        },
      },
    },
  };

  componentDidMount() {
    // Sign up to auth updates.
    this.authStateListenerSubscription = firebase.addLoginDataListener(
      this.state.authUserContext.mutationFunctions.updateAuthdataFromApi,
    );
  }

  componentWillUnmount() {
    // Unsubscribe to auth updates.
    this.authStateListenerSubscription();
  }

  constructor(props) {
    super(props);
    /* change the context of all mutation functions to the context of this
        object */
    Object.entries(this.state).forEach(([_, contextObj]) =>
      Object.entries(contextObj.mutationFunctions).forEach(([functionName, mutationFunc]) => {
        contextObj.mutationFunctions[functionName] = mutationFunc.bind(this);
      }),
    );
  }
  render() {
    return (
      <AuthContext.Provider value={this.state.authUserContext}>
        <apiFetchContext.Provider value={this.state.apiFetchContext}>{this.props.children}</apiFetchContext.Provider>
      </AuthContext.Provider>
    );
  }
}

const withAuthContext = Component => props => (
  <AuthContext.Consumer>{value => <Component {...props} authContext={value} />}</AuthContext.Consumer>
);

const withAPIContext = Component => props => (
  <apiFetchContext.Consumer>{value => <Component {...props} apiContext={value} />}</apiFetchContext.Consumer>
);

const withDataProvider = Component => props => {
  var AuthedComponent = withAuthContext(Component);
  return (
    <DataProvider>
      <AuthedComponent {...props} />
    </DataProvider>
  );
};

export { DataProvider, withAuthContext, withDataProvider, withAPIContext };
