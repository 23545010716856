import { withStyles, Grid, Button } from '@material-ui/core';
import React from 'react';
import { AWSInstanceUtils } from '../utils';
import { SingleInstancePanel, RulesTable } from './poc';

const styles = theme => ({
  width90: {
    width: '90%',
  },
});

const SetPolicyView = ({ instance, classes, startupButtonClick, shutdownButtonClick }) => (
  <div className={classes.width90}>
    <SingleInstancePanel {...AWSInstanceUtils.getInstanceProperties(instance)} />
    <Grid container justify='space-between'>
      <Grid item>
        <Button color='secondary' variant='contained' onClick={shutdownButtonClick}>
          Shutdown now
        </Button>
      </Grid>
      <Grid item>
        <Button color='primary' variant='contained' onClick={startupButtonClick}>
          Startup now
        </Button>
      </Grid>
    </Grid>
    <RulesTable />
  </div>
);

export default withStyles(styles)(SetPolicyView);
