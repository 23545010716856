import moment from 'moment';

const AWSInstanceUtils = {
  getInstanceProperties: instance => {
    const instanceNameTag = instance.Tags.find(ele => ele.Key === 'Name');
    const instanceName = (instanceNameTag && instanceNameTag.Value) || instance.InstanceId;

    const launchTime = instance.LaunchTime;

    return {
      instanceTags: instance.Tags,
      instanceName: instanceName,
      instanceType: instance.InstanceType,
      instanceState: instance.State, // 0 : pending, 16 : running, 32 : shutting-down, 48 : terminated, 64 : stopping, 80 : stopped
      upTime: moment(launchTime).fromNow(),
      launchTime: launchTime,
      instance: instance,
    };
  },
};

export default AWSInstanceUtils;
