import { withStyles, withTheme } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

const styles = theme => ({
  LandingContainer: {
    flexGrow: 1,
    height: '100%',
    weight: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  flexContent: {
    alignSelf: 'flex-end',
  },
  leftContent: {
    position: 'relative',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '0%',
      display: 'none',
    },
    backgroundColor: 'lightYellow',
    zIndex: 3,
    '&:after': {
      position: 'absolute',
      top: 0,
      right: 0,
      display: 'block',
      content: '""',
      width: '20%',
      height: '100%',
      backgroundColor: 'lightYellow',
      transformOrigin: 'top right',
      transform: 'skewX(+5deg)',
      zIndex: 2,
    },
  },
  rightContent: {
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
    zIndex: 4,
  },
  rightContentAlignCenter: {
    alignItems: 'center',
  },
  rightContentWidth: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  rightContentOnlyWidth: {
    width: '100%',
  },
});

class Landing extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.LandingContainer}>
          {!this.props.rightOnly && <div className={classes.leftContent} />}
          <div
            className={classNames(classes.rightContent, classes.rightContentWidth, {
              [classes.rightContentOnlyWidth]: this.props.rightOnly,
              [classes.rightContentAlignCenter]: !this.props.startOnTop,
            })}
          >
            {this.props.Content && <this.props.Content />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTheme()(withStyles(styles)(Landing));
