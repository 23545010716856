import { Card, CardContent, Typography, withStyles } from '@material-ui/core';
import React from 'react';

const styles = theme => ({
  Cards: {
    margin: theme.spacing.unit,
    maxWidth: '95%',
    backgroundColor: theme.palette.primary.light,
  },
  cardText: {
    overflowWrap: 'break-word',
  },
});

const BasicDataRenderComponent = props => {
  const { firebaseAuthObject } = props.authContext.data;
  const { firestoreUserObject } = props.apiContext.data;
  const welcomeMessage = firebaseAuthObject
    ? `Hello, welcome to Shutdown, User: ${firebaseAuthObject.email}. ` +
      `We also know your preferences ${JSON.stringify(firestoreUserObject)}.`
    : `You are not allowed to be here, you are not logged in!`;
  return (
    <React.Fragment>
      <StyledDashboardTosMessage message={welcomeMessage} classes={props.classes} />
    </React.Fragment>
  );
};

const DashboardTosMessage = ({ message, classes }) => {
  return (
    <Card className={classes.Cards}>
      <CardContent>
        <Typography component='p' className={classes.cardText}>
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
};

const StyledDashboardTosMessage = withStyles(styles)(DashboardTosMessage);

export default BasicDataRenderComponent;
