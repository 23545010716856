import app from 'firebase/app';
import 'firebase/firebase-auth';
import 'firebase/firebase-firestore';
import { fbConfig } from './config';

class Firebase {
  constructor() {
    app.initializeApp(fbConfig);
    this.db = app.firestore();
    this.auth = app.auth();
    this.auth.onAuthStateChanged(this.manageAuthConstants, this.handleAuthError);
  }

  manageAuthConstants = authDetails => {
    this.authUser = authDetails;
    if (authDetails && authDetails.uid) {
      this.authUserDocRef = this.db.collection('users').doc(authDetails.uid);
      this.authUserAwsDocRef = this.db.collection('awsCreds').doc(authDetails.uid);

      // set documents to default values.
      this.authUserDocRef.get().then(document => {
        if (!document.exists) {
          this.authUserDocRef.set({ rememberMe: true, tosAccepted: false });
        }
      });
      this.authUserAwsDocRef.get().then(document => {
        if (!document.exists) {
          this.authUserAwsDocRef.set({
            AWS_ACCESS_KEY_ID: '',
            AWS_ACCESS_KEY_SECRET: '',
            awsAccessKeyNotes: '',
          });
        }
      });
    }
  };

  addLoginDataListener = callbackContextFn => {
    return this.auth.onAuthStateChanged(callbackContextFn);
  };

  addUserStoreListener = callbackContextFn => {
    return this.db
      .collection('users')
      .doc(this.authUser.uid)
      .onSnapshot(callbackContextFn);
  };

  addAWSStoreListener = callbackContextFn => {
    return this.db
      .collection('awsCreds')
      .doc(this.authUser.uid)
      .onSnapshot(callbackContextFn);
  };

  handleAuthError = error => {
    // TODO: Remove client side auth, but potentially add a server log (generic)
    console.log('we have an authentication error', error);
  };

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => {
    var provider = new app.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    var scopedAuth = this.auth;
    var getProviderForProviderId = this.getProviderForProviderId;
    this.auth.useDeviceLanguage();
    this.auth
      .signInWithPopup(provider)
      .then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
        // // The signed-in user info.
        // var user = result.user;
        // ...
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(errorCode, errorMessage, email, credential);

        if (error.code === 'auth/account-exists-with-different-credential') {
          // Step 2.
          // User's email already exists.
          // The pending GitHub credential.
          var pendingCred = error.credential;
          // The provider account's email address.
          var emailInError = error.email;
          // Get sign-in methods for this email.
          scopedAuth.fetchSignInMethodsForEmail(emailInError).then(function(methods) {
            var provider = getProviderForProviderId(methods[0]);
            scopedAuth.signInWithPopup(provider).then(function(result) {
              result.user.linkAndRetrieveDataWithCredential(pendingCred);
            });
          });
        }
        // ...
      });
  };

  doSignInWithGithub = () => {
    var provider = new app.auth.GithubAuthProvider();
    provider.addScope('repo');
    this.auth.useDeviceLanguage();

    var scopedAuth = this.auth;
    var getProviderForProviderId = this.getProviderForProviderId;
    this.auth
      .signInWithPopup(provider)
      .then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        console.log(token, user);
        // ...
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(errorCode, errorMessage, email, credential);

        if (error.code === 'auth/account-exists-with-different-credential') {
          // Step 2.
          // User's email already exists.
          // The pending GitHub credential.
          var pendingCred = error.credential;
          // Get sign-in methods for this email.
          scopedAuth.fetchSignInMethodsForEmail(email).then(function(methods) {
            var provider = getProviderForProviderId(methods[0]);
            scopedAuth.signInWithPopup(provider).then(function(result) {
              result.user.linkAndRetrieveDataWithCredential(pendingCred);
            });
          });
        }
      });
  };

  getProviderForProviderId = provider => {
    switch (provider) {
      case 'google.com':
        return new app.auth.GoogleAuthProvider();
      case 'Github':
        return new app.auth.GithubAuthProvider();
      default:
        console.log('unknown provider', provider);
        return null;
    }
  };

  doSignInWithEmailAndPassword = (email, password) => {
    // First sign out any existing user
    if (this.authUser) this.auth.signOut();
    // Now signIn the new user
    this.auth.signInWithEmailAndPassword(email, password);
  };

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Firestore API ***
  updateUserRemember = rememberMe => this.authUserDocRef.update({ rememberMe });

  updateTosAccepted = tosAccepted => this.authUserDocRef.update({ tosAccepted });

  // Expect to be an object with key value pairs that update the document via a shallow merge
  updateUserData = newData => this.authUserDocRef.update(newData);

  updateUserAwsData = newData => this.authUserAwsDocRef.update(newData);
}

const firebase = new Firebase();
export default firebase;
