import axiosBase from 'axios';
import firebase from '../firebase';

const functionNameAndPath = 'awsBackend/';
class HttpApiInterface {
  axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_FUNCTIONS_HOSTING_DOMAIN}${functionNameAndPath}`,
    timeout: 30000,
  });

  // Async returns a promise which resolves to a header or throws an error.
  constructAuthHeader = () => {
    return firebase.authUser.getIdToken().then(idToken => ({
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }));
  };

  getRaw = path => this.constructAuthHeader().then(headers => this.axios.get(path, headers));

  postRaw = path => this.constructAuthHeader().then(headers => this.axios.post(path, null, headers));

  getEC2Instances = () => {
    return this.getRaw('/ec2Instances');
  };
  getEC2KeyPairs = () => {
    return this.getRaw('/ec2KeyPairs');
  };
  updateAWSInstanceState = (instanceId, operation) => {
    return this.postRaw(`/ec2Action/${instanceId}?operation=${operation}`);
  };
}

const httpApiInterface = new HttpApiInterface();
export default httpApiInterface;
