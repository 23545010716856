import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Login, SetPolicy, SignUp } from './components';
import { withAuthContext } from './contexts';
import { Dashboard, Landing, NotFound, withLanding } from './layouts';
import { MyAccounts } from './pages';

const LogoutSubComponent = withAuthContext(props => {
  props.authContext.mutationFunctions.doLogout();
  return <Redirect to='/login' />;
});

const RenderRoutes = () => {
  const allRoutes = [
    <Route exact path='/' />,
    <Route exact path='/landing' component={Landing} />,
    <Route exact path='/login' component={withLanding(Login)} />,
    <Route exact path='/signup' component={withLanding(SignUp)} />,
    <Route exact path='/dashboard' component={Dashboard} />,
    <Route exact path='/signout' component={LogoutSubComponent} />,
    <Route
      exact
      path='/setPolicy/:InstanceId'
      render={props => {
        const Component = withLanding(SetPolicy);
        return <Component {...props} rightOnly={true} />;
      }}
    />,
    <Route
      exact
      path='/myAccounts'
      render={props => {
        const Component = withLanding(MyAccounts);
        return <Component {...props} startOnTop={true} rightOnly={true} />;
      }}
    />,
    <Route component={NotFound} />,
  ];

  const allRoutesWithKey = allRoutes.map((element, index) => React.cloneElement(element, { key: index }));
  return (
    <Switch>
      {/* Each of these routes are flexChildren with flexDirection column */}
      {allRoutesWithKey}
    </Switch>
  );
};

export default RenderRoutes;
