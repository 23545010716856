import { List, ListItem, ListItemText, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { MaterialThemeProvider } from './components';
import { DataProvider } from './contexts';
import RenderRoutes from './routes';

const styles = theme => ({
  rootContainer: {
    minHeight: '100vh',
    minWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  routingContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  horizontalChildren: {},
  denseLists: {
    padding: '2px',
  },
});

class App extends Component {
  createColumnRendering = (classes, route1, route2) => {
    return (
      <div className={classes.horizontalChildren}>
        <List dense>
          <ListItem dense divider className={classes.denseLists}>
            <ListItemText>
              <Link to={`/${route1}`}>{route1}</Link>
            </ListItemText>
          </ListItem>
          <ListItem dense divider className={classes.denseLists}>
            <ListItemText>
              <Link to={`/${route2}`}>{route2}</Link>
            </ListItemText>
          </ListItem>
        </List>
      </div>
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <MaterialThemeProvider>
          <Router basename={process.env.PUBLIC_URL}>
            <DataProvider>
              <React.Fragment>
                <div className={classes.rootContainer}>
                  <div className={classes.routingContainer}>
                    {this.createColumnRendering(classes, 'login', 'signup')}
                    {this.createColumnRendering(classes, 'landing', 'dashboard')}
                    {this.createColumnRendering(classes, 'myAccounts', 'signout')}
                  </div>
                  {RenderRoutes()}
                </div>
              </React.Fragment>
            </DataProvider>
          </Router>
        </MaterialThemeProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(App);
