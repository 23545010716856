import {
  Button,
  Card,
  CardContent,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  FormGroup,
  Icon,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import React from 'react';

const styles = theme => ({
  FormDefault: {
    margin: theme.spacing.unit,
    width: '100%',
    backgroundColor: theme.palette.primary['100'],
    opacity: '0.95',
  },
  FormError: {
    backgroundColor: theme.palette.error[100],
    opacity: 0.5,
  },
  cardText: {
    overflowWrap: 'break-word',
  },
  validateButton: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  connectivityIcon: {
    marginTop: 0,
    marginRight: 0,
    position: 'relative',
    right: '0px',
    top: '0px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '-10px',
    paddingTop: '-10px',
    flexGrow: 1,
  },
  errorColor: {
    color: theme.palette.error.dark,
  },
  healthColor: {
    color: theme.palette.primary.dark,
  },
  iconMargin: {
    marginRight: '3px',
    color: theme.palette.primary.dark,
  },
  ExpansionPanelDetails: {
    padding: 0,
  },
  ExpansionPanelSummary: {
    marginBottom: 0,
    minHeight: 0,
  },
});

const AWSAccountDetailsForm = props => {
  var {
    isEditable,
    classes,
    rememberMe,
    tosAccepted,
    AWSAccessKeyId,
    AWSAccessKeySecret,
    AWSAccessKeyNotes,
    AWSRegion,
    handleFieldStatusChange,
    accountNickName,
  } = props;
  accountNickName = 'My One and Only Account';
  return (
    <ExpansionPanel className={`${classes.FormDefault}`}>
      <ExpansionPanelSummary className={`${classes.ExpansionPanelSummary}`} expandIcon={<ExpandMoreIcon />}>
        <Typography>{accountNickName}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={`${classes.ExpansionPanelDetails}`}>
        <Card
          className={classNames(classes.FormDefault, {
            [classes.FormError]: !isEditable,
          })}
        >
          <CardContent>
            <form>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='rememberMe'
                      checked={rememberMe}
                      onChange={handleFieldStatusChange}
                      color='primary'
                    />
                  }
                  label='Remember Me'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='tosAccepted'
                      checked={tosAccepted}
                      onChange={handleFieldStatusChange}
                      color='primary'
                    />
                  }
                  label='Accept Terms'
                />
                <div className={classes.connectivityIcon}>
                  {isEditable && <Icon className={classes.healthColor}>wifi_tethering</Icon>}
                  {!isEditable && <Icon className={classes.errorColor}>signal_wifi_off</Icon>}
                </div>
              </FormGroup>

              <FormGroup>
                <TextField
                  margin='none'
                  required
                  error={!AWSAccessKeyId}
                  disabled={!isEditable}
                  name='AWSAccessKeyId'
                  label='AWS Access Key ID'
                  fullWidth
                  autoComplete='AWSAccessKeyId'
                  value={AWSAccessKeyId}
                  onChange={handleFieldStatusChange}
                />
                <TextField
                  margin='none'
                  required
                  disabled={!isEditable}
                  error={!AWSAccessKeySecret}
                  name='AWSAccessKeySecret'
                  label='AWS Access Key Secret'
                  fullWidth
                  autoComplete='AWSAccessKeySecret'
                  value={AWSAccessKeySecret}
                  onChange={handleFieldStatusChange}
                />
                <TextField
                  margin='none'
                  disabled={!isEditable}
                  name='AWSAccessKeyNotes'
                  label='Notes about your key'
                  fullWidth
                  multiline
                  rowsMax='4'
                  autoComplete='AWSAccessKeyNotes'
                  value={AWSAccessKeyNotes}
                  onChange={handleFieldStatusChange}
                />
                <TextField
                  margin='none'
                  disabled={!isEditable}
                  required
                  error={!AWSRegion}
                  name='AWSRegion'
                  label='AWS Region (e.g. us-east-1)'
                  fullWidth
                  autoComplete='AWSRegion'
                  value={AWSRegion}
                  onChange={handleFieldStatusChange}
                />
                <div className={classes.validateButton}>
                  <Icon fontSize='default' className={classes.iconMargin}>
                    playlist_add_check
                  </Icon>
                  <Button color='primary' disabled={!isEditable} size='small' variant='contained'>
                    Validate
                  </Button>
                </div>
              </FormGroup>
            </form>
          </CardContent>
        </Card>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default withStyles(styles)(AWSAccountDetailsForm);
