import { withStyles } from '@material-ui/core';
import React from 'react';

const style = theme => ({
  themeDisplay: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  contentText: {
    width: '50%',
    fontSize: '60px',
    alignSelf: 'center',
  },
});

class NotFound extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.themeDisplay}>
        <h2 className={classes.contentText}>You are in the wrong place</h2>
      </div>
    );
  }
}

export default withStyles(style)(NotFound);
