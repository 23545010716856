import { Card, CardContent, Chip, Grid, Typography, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = theme => ({
  Cards: {
    marginLeft: theme.spacing.unit,
    backgroundColor: theme.palette.primary.light,
    minWidth: '400px',
  },
  runState: {
    fontSize: 18,
  },
  name: {
    fontSize: 25,
  },
  running: {
    height: '40px',
    width: '10px',
    borderRadius: '7px',
    backgroundColor: 'red',
    display: 'inline-block',
  },
  others: {
    height: '40px',
    width: '10px',
    borderRadius: '7px',
    backgroundColor: green[500],
    display: 'inline-block',
  },
  removeWidthFromContainer: {
    width: 'unset',
  },
  key: {
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: theme.palette.primary[400],
    borderRight: `solid 1px`,
    borderRadius: '20px',
  },
  value: {
    paddingLeft: '5px',
    paddingRight: '5px',
    borderRight: `solid 1px`,
    borderRadius: '20px',
    backgroundColor: theme.palette.primary[800],
  },
  chipLabel: {},
  chipRoot: {
    height: '24px',
    marginTop: '2px',
  },
});

const SingleInstancePanel = ({
  classes,
  instanceName,
  instanceType,
  instanceState,
  upTime,
  launchTime,
  instance,
  instanceTags,
}) => (
  <Card className={classes.Cards}>
    <CardContent>
      <Grid container spacing={8} direction='column'>
        <Grid item>
          <InstanceCardTitle instanceName={instanceName} instanceType={instanceType} classes={classes} />
        </Grid>
        <Grid item container spacing={16} justify='space-between'>
          <InstanceCardButton
            instanceState={instanceState}
            instanceUptimeString={upTime}
            instanceLaunchTime={moment(launchTime).calendar()}
            classes={classes}
          />
        </Grid>
        <Grid item>
          {instanceTags &&
            instanceTags.map((tag, index) => (
              <Chip
                key={index}
                classes={{ label: classes.chipLabel, root: classes.chipRoot }}
                color='secondary'
                variant='default'
                label={<SingleTag classes={classes} {...tag} />}
              />
              // <div><span className={classes.divBorder}><SingleTag classes={classes} {...tag} /></span></div>
            ))}
        </Grid>
        <Grid item>
          <Typography>
            Shutdown policy is missing. Click{' '}
            <Link
              to={{
                pathname: `/setPolicy/${instance.InstanceId}`,
                state: instance,
              }}
            >
              here
            </Link>{' '}
            to set one.
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const InstanceCardTitle = ({ instanceName, instanceType, classes }) => (
  <Grid container justify='space-between' alignItems='center' spacing={24}>
    <Grid item>
      <Typography className={classes.name} color='textSecondary'>
        {instanceName}
      </Typography>
    </Grid>

    <Grid item>
      <Typography>{instanceType}</Typography>
    </Grid>
  </Grid>
);

const InstanceCardButton = ({ instanceState, instanceLaunchTime, instanceUptimeString, classes }) => (
  <>
    <Grid item container spacing={16} alignItems='center' className={classes.removeWidthFromContainer}>
      <Grid item>
        <span className={instanceState.Code === 16 ? classes.running : classes.others} />
      </Grid>
      <Grid item>
        <Typography className={classes.runState}>{instanceState.Name}</Typography>
      </Grid>
    </Grid>

    <Grid item>
      <Typography variant='h5' align='right'>
        {instanceUptimeString}
      </Typography>
      <Typography align='right'>
        <i>Started</i> {instanceLaunchTime}
      </Typography>
    </Grid>
  </>
);

const SingleTag = ({ classes, Key, Value }) => (
  <>
    <span className={classes.key}>{Key}</span>
    <span className={classes.value}>{Value}</span>
  </>
);

export default withStyles(styles)(SingleInstancePanel);
