import React from 'react';
import { InstancePanel } from '../components/poc';
import { withAPIContext, withAuthContext } from '../contexts/datacontext';

class Dashboard extends React.Component {
  handleUpdateCheckBox = (event, checked) => {
    this.props.apiContext.mutationFunctions.updateUserData({
      [event.target.id]: checked,
    });
    return;
  };
  handleUpdateTextField = event => {
    this.props.apiContext.mutationFunctions.updateUserAWSData({
      [event.target.id]: event.target.value,
    });
    return;
  };
  awsRefreshOnLoadComplete = false;
  getAWSDataRefresh = nextProps => {
    if (
      nextProps.authContext.data.firebaseAuthObject === '' ||
      nextProps.apiContext.data.firestoreUserAWSObject.AWS_EC2_REGION === ''
    ) {
    } else if (!this.awsRefreshOnLoadComplete) {
      nextProps.apiContext.mutationFunctions.getEC2Data();
      this.awsRefreshOnLoadComplete = true;
    }
  };
  componentDidMount() {
    this.getAWSDataRefresh(this.props);
  }
  componentDidUpdate() {
    this.getAWSDataRefresh(this.props);
  }

  render() {
    if (this.props.authContext.data.firebaseAuthObject === '') {
      return <div>Unable to find data for Authentication or otherwise</div>;
    }
    return (
      <>
        <InstancePanel apiContext={this.props.apiContext} authContext={this.props.authContext} />
      </>
    );
  }
}

export default withAPIContext(withAuthContext(Dashboard));
