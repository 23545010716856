import { Grid } from '@material-ui/core';
import React from 'react';
import { SingleInstancePanel } from '.';
import { AWSInstanceUtils } from '../../utils';

const InstancePanel = props => {
  const { aws_ec2_instances } = props.apiContext.data;
  const instances =
    aws_ec2_instances &&
    aws_ec2_instances.response.Reservations.reduce((accum, arrayElemen) => accum.concat(arrayElemen.Instances), []);
  const gcp_instances = aws_ec2_instances && aws_ec2_instances.response.gcp;
  return (
    <div>
      <Grid container spacing={8}>
        {instances &&
          instances.map((instance, index) => {
            return (
              <Grid item key={index}>
                <SingleInstancePanel {...AWSInstanceUtils.getInstanceProperties(instance)} />
              </Grid>
            );
          })}
        {gcp_instances &&
          gcp_instances.map((instance, index) => (
            <Grid item key={index}>
              <SingleInstancePanel {...instance} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default InstancePanel;
