import { Button, TextField, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withAuthContext } from '../contexts';

const styles = theme => ({
  textField: {
    color: 'red',
  },
  flexChild: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    margin: 'auto',
  },
  widthOfLogin: {
    width: '320px',
    maxWidth: '96%',
  },
  loginButton: {
    marginTop: 20,
  },
});

class Login extends React.PureComponent {
  state = {
    name: '',
    password: '',
  };
  handleChange = property => changedValue => {
    this.setState({ [property]: changedValue.target.value });
  };
  handleLoginSubmit = e => {
    e.preventDefault();
    this.props.authContext.mutationFunctions.doLogin(this.state.name, this.state.password);
    this.props.history.push('/dashboard');
  };
  handleLoginWithGoogle = e => {
    e.preventDefault();
    this.props.authContext.mutationFunctions.doLoginWithGoogle();
    this.props.history.push('/dashboard');
  };
  handleLoginWithGithub = e => {
    e.preventDefault();
    this.props.authContext.mutationFunctions.doLoginWithGithub();
    this.props.history.push('/dashboard');
  };

  render() {
    const { classes } = this.props;
    const Heading = <h1>Login</h1>;
    const loginButton = (
      <Button
        id='login-button'
        fullWidth
        className={classes.loginButton}
        variant='contained'
        color='primary'
        type='submit'
      >
        Login With Username
      </Button>
    );
    const loginWithGoogleButton = (
      <Button
        id='login-with-google-button'
        onClick={this.handleLoginWithGoogle}
        className={classes.loginButton}
        variant='outlined'
        color='secondary'
        fullWidth
      >
        Login With Google
      </Button>
    );
    const loginWithGitHub = (
      <Button
        id='login-with-github-button'
        onClick={this.handleLoginWithGithub}
        className={classes.loginButton}
        variant='outlined'
        color='secondary'
        fullWidth
      >
        Login With Github
      </Button>
    );

    const loginInput = (
      <TextField
        id='outlined-login'
        autoComplete='username'
        label='Email or Username'
        className={classes.textField}
        value={this.state.name}
        onChange={this.handleChange('name')}
        margin='normal'
        variant='outlined'
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
    );

    const passwordInput = (
      <TextField
        id='outlined-password'
        label='Password'
        autoComplete='current-password'
        className={classes.textField}
        value={this.state.password}
        onChange={this.handleChange('password')}
        margin='normal'
        variant='outlined'
        type='password'
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
    );

    return (
      <div>
        <form onSubmit={this.handleLoginSubmit} className={classNames(classes.widthOfLogin, classes.flexChild)}>
          <div>{Heading}</div>
          <div>{loginInput}</div>
          <div>{passwordInput}</div>
          <div>{loginButton}</div>
          <div>{loginWithGoogleButton}</div>
          <div>{loginWithGitHub}</div>
        </form>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(withAuthContext(Login)));
