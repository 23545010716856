import { withStyles } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router';
import { AWSAccountDetailsForm, BasicDataRenderComponent } from '../components/poc';
import { withAPIContext, withAuthContext } from '../contexts';

const styles = theme => ({});

class MyAccounts extends React.Component {
  state = {
    AWSAccessKeyId: '',
    AWSAccessKeySecret: '',
    AWSAccessKeyNotes: '',
    AWSRegion: '',
    rememberMe: false,
    tosAccepted: false,
    isEditable: false,
  };

  correspondenceForNaming = {
    AWSAccessKeyId: 'AWS_ACCESS_KEY_ID',
    AWSAccessKeySecret: 'AWS_ACCESS_KEY_SECRET',
    AWSAccessKeyNotes: 'awsAccessKeyNotes',
    AWSRegion: 'AWS_EC2_REGION',
  };

  handleFieldStatusChange = (event, checked) => {
    if (this.state.isEditable) {
      // We have a text field
      if (checked === undefined || checked === null) {
        this.state.updateUserAWSData({
          [this.correspondenceForNaming[event.target.name]]: event.target.value,
        });
      } else {
        this.state.updateUserData({
          [event.target.name]: checked,
        });
      }
    }
  };
  static getDerivedStateFromProps(props, _) {
    if (props.apiContext && props.apiContext.data) {
      const { firestoreUserAWSObject } = props.apiContext.data;
      const { firestoreUserObject } = props.apiContext.data;

      const { AWS_ACCESS_KEY_ID, AWS_ACCESS_KEY_SECRET, AWS_EC2_REGION, awsAccessKeyNotes } = firestoreUserAWSObject;

      const { rememberMe, tosAccepted } = firestoreUserObject;

      return {
        AWSAccessKeyId: AWS_ACCESS_KEY_ID,
        AWSAccessKeySecret: AWS_ACCESS_KEY_SECRET,
        AWSAccessKeyNotes: awsAccessKeyNotes,
        AWSRegion: AWS_EC2_REGION,
        rememberMe: rememberMe,
        tosAccepted: tosAccepted,
        isEditable: true,

        updateUserAWSData: props.apiContext.mutationFunctions.updateUserAWSData,
        updateUserData: props.apiContext.mutationFunctions.updateUserData,
      };
    }
    return null;
  }

  render() {
    // Don't mess with ththe renderi of the component. But on mount let's setup a
    // two way binding between the state and wherever the data is supposed to be.
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <BasicDataRenderComponent {...this.props} />
        <AWSAccountDetailsForm {...this.state} handleFieldStatusChange={this.handleFieldStatusChange} />
      </div>
    );
  }
}

export default withStyles(styles)(withAPIContext(withAuthContext(withRouter(MyAccounts))));
