import React from 'react';
import { withRouter } from 'react-router';
import { SetPolicyView } from '.';
import { withAPIContext } from '../contexts';

class SetPolicy extends React.Component {
  handleStartButtonClick = () => {
    this.props.apiContext.mutationFunctions.updateAWSInstanceState(this.props.match.params.InstanceId, 'start');
  };
  handleShutdownButtonClick = () => {
    this.props.apiContext.mutationFunctions.updateAWSInstanceState(this.props.match.params.InstanceId, 'stop');
  };
  getInstanceIfPresent = (InstanceId, aws_ec2_instances) => {
    const instances =
      aws_ec2_instances &&
      aws_ec2_instances.response.Reservations.reduce((accum, arrayElemen) => accum.concat(arrayElemen.Instances), []);
    return instances && instances.find(ele => ele.InstanceId === InstanceId);
  };
  render() {
    const { match, location, apiContext } = this.props;
    var instances = apiContext.data.aws_ec2_instances;
    var instance = location.state; // If we were navigated here with an instance, let's just use it.
    var InstanceId = match ? match.params.InstanceId : null;
    var ServerInstanceIfPresent = this.getInstanceIfPresent(InstanceId, instances);
    if (ServerInstanceIfPresent) {
      instance = ServerInstanceIfPresent;
    }
    const policyView = (
      <SetPolicyView
        instance={instance}
        startupButtonClick={this.handleStartButtonClick}
        shutdownButtonClick={this.handleShutdownButtonClick}
      />
    );
    const error = <div> No instance Found </div>;
    return instance ? policyView : error;
  }
}

export default withAPIContext(withRouter(SetPolicy));
