/* This is a tabular component that visualizes and depicts how we would
show all the policy rules and their impact on an instance's uptime */
import React from 'react';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  rulesTable: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    minWidth: 700,
  },
});

const headers = [
  {
    name: 'Rule Type',
  },
  {
    name: 'Description',
  },
  {
    name: 'Shutdown Criteria',
  },
  {
    name: 'Restart Criteria',
  },
  {
    name: 'Notification Threshold',
    align: 'right',
  },
];

const rules = [
  [
    'SHUTDOWN',
    'Shutdown all machines at 9pm on all days',
    'machine: all, time: 9pm',
    'none',
    'Notification Pattern: Email: 10mins, Text: 2mins',
  ],
  [
    'STARTUP',
    'Start all machines at 8pm on all week days',
    'none',
    'machine: all, time: 8pm weekday',
    'Notification Pattern: Email after start',
  ],
];

const options = {
  filterType: 'multiselect',
};

// Inteded for iteration with an array which then gives you (headerRowSpec, index)
// const createHeaderCell = ({definition, align}, index) => (
//     <TableCell key={index} align={align}>{definition}</TableCell>
// )

const RulesTable = ({ classes }) => (
  // <Table className={classes.rulesTable}>
  //     <TableHead>
  //         <TableRow>
  //             {headers.map(createHeaderCell)}
  //         </TableRow>
  //     </TableHead>
  //     <TableBody>
  //         {rules.map((rule, idx) => (
  //             <TableRow key={idx}>
  //                 {rule.map(createHeaderCell)}
  //             </TableRow>
  //         ))}
  //     </TableBody>
  // </Table>
  <MUIDataTable title={'Employee List'} data={rules} columns={headers} options={options} />
);

export default withStyles(styles)(RulesTable);
